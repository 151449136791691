import React, { useContext } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import ChevronUpIcon from 'mdi-react/ChevronUpIcon';
import ChevronDownIcon from 'mdi-react/ChevronDownIcon';
import { ITheme } from "../../models";
import AppContext from '../../contexts/AppProvider';


type RuleNames = 'root' | 'inner' | 'button' | 'page';
interface IProps {
    onPageUp: any;
    onPageDown: any;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({

    root: {
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        width: "50px",
        //background: "#fff",
        textAlign: "center;"
    },
    inner: {
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
    },
    button: {
        cursor: "pointer",
    },
    page: {
        display: "block",
    }
})

const PageIndicator = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const appContext = useContext(AppContext);
    const classes = useStyles({ ...props, theme });
    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                <ChevronUpIcon className={classes.button} onClick={props.onPageUp} />
                <span className={classes.page}>{appContext.page}</span>
                <ChevronDownIcon className={classes.button} onClick={props.onPageDown} />
            </div>
        </div>);
}

export default PageIndicator;