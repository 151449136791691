import React, { useEffect, useRef, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { useNavigate, useParams } from 'react-router-dom';
import { useImages, useUser, useWidth } from '../../hooks';
import { IGroup, IImage, IPortfolio, ITheme } from '../../models';
import Button from '../button/Button';
import ExpandingPanel from '../expandingPanel/ExpandingPanel';
import PortfolioThumbnail from './PortfolioThumbnail';


type RuleNames = 'root' | 'inner' | 'title' | 'image' | 'content' | 'panel';
interface IProps {
    item: IPortfolio;
    group: IGroup;
    itemsPerPage: number;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({

    root: {
        display: "inline-block",
        verticalAlign: "top",
        width: props => `${100 / props.itemsPerPage}%`,
        padding: "10px",
    },
    inner: {
        // border: "1px solid",
        //borderRadius: "10px",
        // padding: "10px",
        background: ({ theme }) => theme.colorPrimaryLighter,
        color: ({ theme }) => theme.colorSecondary,
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.6)',
        fontSize: 12,
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        display: 'block',
        marginBlockStart: 0,
        marginBlockEnd: 0,
        marginInlineStart: 0,
        marginInlineEnd: 0,
    },
    image: {
        //borderTopLeftRadius: "10px",
        //borderTopRightRadius: "10px",
        width: "100%",
        height: "auto",
    },
    content: {
        padding: "10px",
        '& > p': {
            minHeight: 150,
            maxHeight: 150,
            overflow: 'hidden',
            whiteSpace: 'break-spaces',
            textOverflow: 'ellipsis'
        }
    },
    panel: {
        position: 'absolute',
        background: ({ theme }) => theme.colorSecondary,
        border: ({ theme }) => `1px solid ${theme.colorPrimary}`
    }
})


interface IBound {
    top: number;
    left: number;
    width: number;
    height: number;
}
interface IButtonRef {
    getBounds: () => IBound | void
}
interface IParams {
    group: string,
    project: string
}

const PortfolioItem = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });

    const [pos, setPos] = useState<IBound | null>(null);
    const [image, setImage] = useState<IImage>();

    const button = useRef<IButtonRef>(null);
    const { breakpoint } = useWidth();

    const { group, project } = useParams<keyof IParams>();

    const images = useImages();
    const user = useUser();
    const navigate = useNavigate();

    useEffect(() => {
        if (props?.item?.images && props.item.images.length > 0) {
            let locID = props.item.images[0].id
            let locImg = images.find(f => f.id === locID);
            setImage(locImg);
        }

    }, [images, props.item.images])


    const onMoreClick = () => {
        navigate(`/${group}/${props.item.slug}`)
    }
    const openItem = () => {
        if (button.current) {
            var bounds = button.current.getBounds();
            if (bounds) {
                setPos(bounds);
            }
            else {
                setPos(null);
                //navigate(`/${group}`)
            }
        }
    }
    const closeItem = () => {
        setPos(null)
    }
    const onDetailsClose = () => {
        navigate(`/${group}`)
    }

    useEffect(() => {
        console.log("CALL");
        if (props.item && project) {
            if (props.item.slug === project) {
                if (!pos) {
                    openItem();
                }
            }
            else {
                closeItem();
            }
        }
        if (!project) {
            closeItem();
        }
    }, [openItem, closeItem, project, props.item]);

    return (
        <div className={classes.root}>
            <div className={classes.inner}>
                {/* {image && image.image && <img src={(image?.image) || undefined} className={classes.image} alt={props.item.title} />} */}
                <PortfolioThumbnail image={image?.image} title={props.item.title} />
                <div className={classes.content}>
                    <h1 className={classes.title}>{props.item.title}</h1>
                    <p>{props.item.text}</p>
                    <Button ref={button} label="More..." fullWidth onClick={onMoreClick} marginTop={40} />
                </div>
            </div>
            {pos && <ExpandingPanel isLoggedIn={!!user} top={pos.top} left={pos.left} width={pos.width} height={pos.height} widthEnd={window.innerWidth * 90 / 100} heightEnd={window.innerHeight * 90 / 100} onClose={onDetailsClose} data={props.item} breakpoint={breakpoint} group={props.group} />}
        </div>
    );
}

export default PortfolioItem;