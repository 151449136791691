import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, query } from 'firebase/firestore';
import { getApp } from 'firebase/app';
//import { getAuth, createUserWithEmailAndPassword,signInWithEmailAndPassword, signOut, sendPasswordResetEmail, updatePassword  } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCxqnjSdN8KTCSl86jRhr8DjkQ-CdL37G8",
  authDomain: "pilifeu.firebaseapp.com",
  databaseURL: "https://pilifeu.firebaseio.com",
  projectId: "pilifeu",
  storageBucket: "pilifeu.appspot.com",
  messagingSenderId: "693749888078",
  appId: "1:693749888078:web:c2867e6cfefe9ce69cbf1f"
};


const initializeFirebase = () => {
  initializeApp(firebaseConfig);
}
const getNextId = async (table) => {
  let db = getFirestore(getApp());
  const itemsCol = collection(db, table);
  const q = query(itemsCol);
  const itemsSnapshot = await getDocs(q);
  let id = 0;
  if (!itemsSnapshot.empty) {
    id = Math.max.apply(Math, itemsSnapshot.docs.map(item => parseInt(item.id, 10)));
  }
  id++;
  return id.toString();
}



// class Firebase {
//   constructor() {
//     const app = initializeApp(firebaseConfig);
//     this.db = getFirestore(app);

//     this.auth = getAuth();

//   }

//   doCreateUserWithEmailAndPassword = (email, password) => createUserWithEmailAndPassword(getAuth(),email, password);
//   doSignInWithEmailAndPassword = (email, password) => signInWithEmailAndPassword (this.auth, email, password);
//   doSignOut = () => signOut(this.auth);

//   doPasswordReset = (email) => sendPasswordResetEmail(this.auth,email);
//   doPasswordUpdate = (password) => updatePassword(this.auth.currentUser,password);

//   getNextId = async (table) =>{
//     const itemsCol = collection(this.db,table);
//     const itemsSnapshot = await getDocs(itemsCol);
//     let id = 0;
//     if(itemsSnapshot.val()){
//       id= Math.max.apply(Math,Object.keys(itemsSnapshot.val().map(doc => parseInt(doc))));
//     }
//     id++;
//     return id;
//   }

//   writePortfolioData = (id, title, text, image, group) => {
//     var promise = this.db.ref("portfolio/" + id).set({
//       id: id,
//       group: group,
//       title: title,
//       text: text,
//       image: image
//     });
//     this.db.ref("groups/" + group + "/portfolios/" + id).set(true);
//     return promise;
//   };
// }

// export default Firebase;

export { initializeFirebase, getNextId }
