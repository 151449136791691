import React from 'react';
import { useUser } from '../../hooks';
import { Login, Register } from "../../components/auth";
import MainPage from "./MainPage";

interface IProps { }


const Backend = (props: IProps) => {
  const user = useUser();

  const renderLogin = () => {
    return <Login />;
  }
  const renderRegister = () => {
    return <Register />;
  }
  const renderBackend = () => {
    return <MainPage />;
  }

  return (
    <>
      {!user && renderLogin()}
      {/* {!user && renderRegister()} */}
      {!!user && renderBackend()}
    </>
  )
}

export default Backend;