import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";
import Header from '../../components/backend/Header';

import { Route, Link, Routes } from "react-router-dom";
import Projects from './Projects';
import Users from './Users';
import Dashboard from './Dashboard';
import Project from './Project';
import ProjectImage from './ProjectImage';
import Groups from './Groups';
import Group from './Group';

type RuleNames = 'root' | 'container' | 'sidebar' | 'content';
interface IProps { }

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {
    },
    container: {
        fontSize: 0,
        height: 'calc(100vh - 54px)',
        width: '100vw',
        '& > div': {
            fontSize: 'initial',
            display: 'inline-block',
            height: '100%',
            verticalAlign: 'top',
        }
    },
    sidebar: {
        width: '300px',
        background: ({ theme }) => theme.colorSecondary,
        '&> a': {
            display: 'block',
            width: '100%',
            padding: 5
        }
    },
    content: {
        width: 'calc(100vw - 300px)',
    }
})

const MainPage = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (
        <div className={classes.root}>
            <Header />
            <div className={classes.container}>
                <div className={classes.sidebar}>
                    <Link to="/backend">Dashboard</Link>
                    <Link to="/backend/groups">Groups</Link>
                    <Link to="/backend/projects">Projects</Link>
                    <Link to="/backend/Users">Users</Link>
                </div>
                <div className={classes.content}>
                    <Routes>
                        <Route path="/" element={<Dashboard />}>

                        </Route>

                        <Route path="groups" element={<Groups />} />
                        <Route path="group" element={<Group />}>
                            <Route path=":id" element={<Group />} />
                        </Route>
                        <Route path="projects" element={<Projects />} />
                        <Route path="project" element={<Project />}>
                            <Route path=":id" element={<Project />}>
                                <Route path="image">
                                    <Route path=":id" element={<ProjectImage />} />
                                </Route>
                            </Route>
                        </Route>
                        <Route path="users" element={<Users />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
}

export default MainPage;