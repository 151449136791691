import React, { useEffect, useState } from 'react';
import { useGroups } from '../../hooks';

//type RuleNames = 'root';
interface IProps {
  id?: string;
}


const GroupViewer = (props: IProps) => {
  const groups = useGroups();

  const [title, setTitle] = useState<string>("");

  useEffect(() => {
    let grp = groups.find(f => f.id === props.id);
    if (grp) {
      setTitle(grp.title)
    }
  }, [groups, props.id])

  return <>{title}</>;
}

export default GroupViewer;