import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged, User } from '@firebase/auth';

const useUser = () => {
    const [user, setUser] = useState<User | null>();

    useEffect(() => {
        return onAuthStateChanged(getAuth(), (usr) => {
            setUser(usr);
        });
    }, []);

    return user;
}

export { useUser };