import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { IImage, ITheme } from "../../models";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useImages, usePortfolio } from '../../hooks';
import { doc, getFirestore, setDoc } from '@firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from '@firebase/storage';
import { getApp } from '@firebase/app';
import { getNextId } from '../../components/firebase/Firebase';

type RuleNames = 'root' | 'action';
interface IProps {
}

interface IParams {
  id?: string;
  imageid?: string;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  root: {
    padding: 25,
    "& > span": {
      display: "block",
      width: "100%",
      maxWidth: 400,
      "& > *": {
        display: "block",
        width: "100%"
      }
    }
  },
  action: {
    textDecoration: "none",
    color: ({ theme }) => theme.colorPrimary,
    "&:visited": {
      color: ({ theme }) => theme.colorPrimary
    },
    "&:hover": {
      color: ({ theme }) => theme.colorPrimaryLighter
    },
    cursor: "pointer",
    margin: "0 5px"
  }
})

const ProjectImage = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });

  const [item, setItem] = useState<IImage>({ id: '', image: '' });
  const [file, setFile] = useState<File>();

  const { id, imageid } = useParams<keyof IParams>();
  const images = useImages();
  const portfolios = usePortfolio();
  const navigate = useNavigate();


  useEffect(() => {
    if (imageid) {
      let img = images.find(f => f.id === imageid);
      if (img) {
        setItem(img);
      }
    }
  }, [imageid, images])

  const onChange = (key: string) => (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    var value = ev.currentTarget.value;
    setItem({ ...item, [key]: value });
  };


  const onSave = async () => {
    if (!file) {
      return;
    }
    let db = getFirestore(getApp());
    let locID = imageid;
    if (!locID) {
      locID = await getNextId("images");
    }

    let storage = getStorage();
    let fileRef = ref(storage, `images/${file.name}`);

    await uploadBytes(fileRef, file);
    let url = await getDownloadURL(fileRef);
    item.image = url;
    item.imageRef = fileRef.fullPath;

    let imgRef = doc(db, "images", locID);
    await setDoc(imgRef, item);

    //update protfolio
    if (!imageid && !!id) {
      let portfolio = portfolios.find(f => f.id === id);
      if (portfolio) {
        if (!portfolio.images) {
          portfolio.images = [imgRef];
        }
        else {
          portfolio.images.push(imgRef);
        }
        await setDoc(doc(db, "portfolio", id), portfolio);
      }
    }

    if (!imageid) {
      navigate(`/backend/project/${id}/image/${locID}`, { replace: true });
    }
  };

  const onFileChange = (ev: React.FormEvent<HTMLInputElement>) => {
    console.log(ev.currentTarget.files);
    if (ev.currentTarget.files && ev.currentTarget.files.length > 0) {
      setFile(ev.currentTarget.files[0]);
    }
  }

  return (
    <div className={classes.root}>
      <Link to={`/backend/project/${id}`}>Back</Link>
      <span>
        <label htmlFor="image">Image</label>
        <input type="file" id="input" accept="image/png, image/jpeg" onChange={onFileChange} />
        <input
          readOnly
          type="text"
          name="image"
          id="image"
          value={item.image}
          onChange={onChange("image")}
        />
      </span>
      <span className={classes.action} onClick={onSave}>
        SAVE
      </span>
    </div>
  );
}

export default ProjectImage;