import { useState, useEffect } from 'react';
import { IGroup, IPortfolio } from '../models';

const useGroupsToShow = (groups: IGroup[], portfolios: IPortfolio[]) => {
  const [groupsToShow, setGroups] = useState<IGroup[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let g = groups.filter(f => portfolios.filter(itemF => itemF.group?.id === f.id).length > 0);
    setGroups(g);
    setIsLoading(false);
  }, [groups, portfolios]);

  return { groupsToShow, isLoading };
}

export { useGroupsToShow };