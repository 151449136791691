import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { IPortfolio, ITheme } from "../../models";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useGroups, usePortfolio } from '../../hooks';
import { doc, getFirestore, setDoc, DocumentData, DocumentReference } from '@firebase/firestore';
import { getApp } from '@firebase/app';
import { getNextId } from '../../components/firebase/Firebase';
import ProjectImages from './ProjectImages';

type RuleNames = 'root' | 'action';
interface IProps { }
interface IParams {
  id?: string;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  root: {
    padding: 25,
    "& > span": {
      display: "block",
      width: "100%",
      maxWidth: 400,
      "& > *": {
        display: "block",
        width: "100%"
      }
    }
  },
  action: {
    textDecoration: "none",
    color: ({ theme }) => theme.colorPrimary,
    "&:visited": {
      color: ({ theme }) => theme.colorPrimary
    },
    "&:hover": {
      color: ({ theme }) => theme.colorPrimaryLighter
    },
    cursor: "pointer",
    margin: "0 5px"
  }
})

const Project = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  const navigate = useNavigate();
  const [item, setItem] = useState<IPortfolio>({ id: '', title: '', text: '', slug: '' });
  const portfolios = usePortfolio();
  const groups = useGroups();
  const { id } = useParams<keyof IParams>();

  useEffect(() => {
    if (id) {
      let portfolio = portfolios.find(f => f.id === id);
      if (portfolio) {
        setItem(portfolio);
      }
    }
  }, [id, portfolios])

  const onChange = (key: keyof IPortfolio) => (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
    var value: string & DocumentReference<DocumentData> & DocumentReference<DocumentData>[] = ev.currentTarget.value as string & DocumentReference<DocumentData> & DocumentReference<DocumentData>[];
    setItem(state => {
      state[key] = value;
      if (key === "link" && !state.link) {
        state.linkLabel = undefined;
      }
      if (key === "title") {
        state.slug = state.title.replaceAll(' ', '-').toLowerCase();
      }
      return { ...state };
    })

  };
  const onChangeGroup = (ev: React.FormEvent<HTMLSelectElement>) => {
    let value = ev.currentTarget.value;
    let grpRef = doc(getFirestore(getApp()), "groups", value);
    setItem({ ...item, group: grpRef });
  }
  const onSave = async () => {
    let db = getFirestore(getApp());
    //item.group = doc(db, "groups", item.group as string);
    let locID = id;
    if (!locID) {
      locID = await getNextId("portfolio");
    }
    await setDoc(doc(db, "portfolio", locID), item);


    if (!id) {
      navigate(`/backend/project/${locID}`, { replace: true });
    }
  };

  return (
    <div className={classes.root}>
      <Link to="/backend/projects">Back</Link>
      <span>
        <label htmlFor="title">Title</label>
        <input
          type="text"
          name="title"
          id="title"
          value={item?.title}
          onChange={onChange("title")}
        />
      </span>
      <span>
        <label htmlFor="slug">Slug</label>
        <input
          type="text"
          name="slug"
          id="slug"
          value={item?.slug}
          onChange={onChange("slug")}
        />
      </span>
      <span>
        <label htmlFor="text">Text</label>
        <textarea
          name="text"
          id="text"
          rows={5}
          value={item?.text}
          onChange={onChange("text")}
        />
      </span>
      <span>
        <label htmlFor="text">Link</label>
        <input
          type="text"
          name="link"
          id="link"
          value={item?.link}
          onChange={onChange("link")}
        />
      </span>
      {item?.link && <span>
        <label htmlFor="text">Link label</label>
        <input
          type="text"
          name="linkLabel"
          id="linkLabel"
          value={item?.linkLabel}
          onChange={onChange("linkLabel")}
        />
      </span>}
      <span>
        <label htmlFor="group">Group</label>
        <select name="group" id="group" onChange={onChangeGroup} value={item?.group?.id}>
          <option value=""></option>
          {groups.map(item => {
            return <option key={item.id} value={item.id}>{item.title}</option>
          })}
        </select>
      </span>
      <span className={classes.action} onClick={onSave}>
        SAVE
      </span>
      {!!id && <ProjectImages item={item} id={id} />}
    </div>
  );
}

export default Project;