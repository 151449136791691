/*import React, { Component } from 'react';
import injectSheet from 'react-jss'
import Cell from './Cell';

const styles = theme => ({
    root: {
        padding: 10,
        width: '100%',
        background: theme.colorSecondary,
        color: theme.fontColorSecondary,
        '&> span':{
            display: 'inline-block',
            width: 150,
            overflow: 'hidden',
        }
    }
});

class RowHeader extends Component {
    render() {
        var { classes, columns } = this.props;
        return (
            <div className={classes.root}>
                {columns.map((col,index) => (<Cell label={col.label} width={col.width} float={col.float} />) )}
            </div>
        );
    }
}

export default injectSheet(styles)(RowHeader);*/

import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";
import Cell from './Cell';

type RuleNames = 'root';
interface IProps {
    columns: any[];
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {
        padding: 10,
        width: '100%',
        background: ({ theme }) => theme.colorSecondary,
        color: ({ theme }) => theme.fontColorSecondary,
        '&> span': {
            display: 'inline-block',
            //width: 150,
            overflow: 'hidden',
        }
    }
})

const RowHeader = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (
        <div className={classes.root}>
            {props.columns.map((col, index) => (<Cell key={index} label={col.label} width={col.width} float={col.float} />))}
        </div>
    );
}

export default RowHeader;