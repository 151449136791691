import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme, IUser } from "../../models";
import { AuthError, getAuth, createUserWithEmailAndPassword } from '@firebase/auth';
import { doc, getFirestore, setDoc } from '@firebase/firestore';
import { getApp } from '@firebase/app';

type RuleNames = 'root';
interface IProps { }

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {}
})

const Register = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });

    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [passwordOne, setPasswordOne] = useState<string>("");
    const [passwordTwo, setPasswordTwo] = useState<string>("");
    const [isInvalid, setIsInvalid] = useState<boolean>(false);
    const [error, setError] = useState<AuthError>();

    useEffect(() => {
        if (!username || !email || !passwordOne || !passwordTwo || passwordOne !== passwordTwo) {
            setIsInvalid(true);
        }
    }, [username, email, passwordOne, passwordTwo])

    const onSubmit = async (ev: React.FormEvent<HTMLFormElement>) => {
        let db = getFirestore(getApp());

        ev.preventDefault();
        try {
            let user = await createUserWithEmailAndPassword(getAuth(), email, passwordOne);
            let dbuser: IUser = {
                id: user.user.uid,
                reader: true,
                writer: false
            };

            await setDoc(doc(db, "users", user.user.uid), dbuser);
        } catch (error: any) {
            setError(error);
        }
        //}).catch(error => {
        //setError(error);
        //});
    }

    const onChange = (ev: React.FormEvent<HTMLInputElement>) => {
        let key = ev.currentTarget.name;
        let val = ev.currentTarget.value;

        switch (key) {
            case "username":
                setUsername(val);
                break;
            case "email":
                setEmail(val);
                break;
            case "passwordOne":
                setPasswordOne(val);
                break;
            case "passwordTwo":
                setPasswordTwo(val)
                break;
        }
    }

    return (
        <form onSubmit={onSubmit} className={classes.root}>
            <input
                name="username"
                value={username}
                onChange={onChange}
                type="text"
                placeholder="Full Name"
            />
            <input
                name="email"
                value={email}
                onChange={onChange}
                type="text"
                placeholder="Email Address"
            />
            <input
                name="passwordOne"
                value={passwordOne}
                onChange={onChange}
                type="password"
                placeholder="Password"
            />
            <input
                name="passwordTwo"
                value={passwordTwo}
                onChange={onChange}
                type="password"
                placeholder="Confirm Password"
            />
            <button disabled={isInvalid} type="submit">Sign Up</button>

            {error && <p>{error.message}</p>}
        </form>
    );
}

export default Register;