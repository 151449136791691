import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { Link } from "react-router-dom";
import { ITheme } from '../../models';
import { SignOut } from "../auth";


type RuleNames = 'root' | '@global';
interface IProps { }

const useStyles = createUseStyles<RuleNames, IProps, ITheme>(theme => ({

  "@global": {
    a: {
      textDecoration: "none",
      color: theme.colorPrimary,
      "&:visited": {
        color: theme.colorPrimary
      },
      "&:hover": {
        color: theme.colorPrimaryLighter
      }
    }
  },
  root: {
    width: "100%",
    padding: 15,
    //height: 50,
    background: theme.colorSecondary
  }
}))

const Header = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  return (
    <div className={classes.root}>
      <Link to="/">Home</Link>
      <SignOut />
    </div>
  );
}

export default Header;