/*import React, { Component } from 'react';
import injectSheet from 'react-jss'
import RowHeader from './RowHeader';
import Row from './Row';

const styles = theme => ({
    root: {

    }
});

class Table extends Component {
    render() {
        var { classes, columns, data } = this.props;
        return (
            <div className={classes.root}>
                <RowHeader columns={columns} />
                <div>
                    {data && data.map((row,index) => (<Row key={index} data={row} columns={columns}/>) )}
                </div>
            </div>
        );
    }
}

export default injectSheet(styles)(Table);
*/

import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";
import RowHeader from './RowHeader';
import Row from './Row';

type RuleNames = 'root';
interface IProps {
    columns: any[];
    data?: any[];
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {}
})

const Table = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (
        <div className={classes.root}>
            <RowHeader columns={props.columns} />
            <div>
                {props.data && props.data.map((row, index) => (<Row key={index} data={row} columns={props.columns} />))}
            </div>
        </div>
    );
}

export default Table;