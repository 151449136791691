import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";


type RuleNames = 'page';
interface IProps {
    children: any;
    color: string;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    page: {
        width: '100vw',
        height: 'calc(var(--vh, 1vh) * 100)',
        position: 'relative'
    }
})

const Page = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (
        <div className={classes.page} style={{ backgroundColor: props.color }}>
            {props.children}
        </div>);
}

export default Page;