import React, { useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";
import { getAuth, signInWithEmailAndPassword, AuthError } from 'firebase/auth'

type RuleNames = 'root' | 'header' | 'error';
interface IProps { }

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  root: {
    position: 'absolute',
    width: 300,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    background: ({ theme }) => theme.colorSecondary,
    boxShadow: '0 0 10px rgba(0,0,0,0.6)',
    padding: 20,
    '& > *': {
      width: 'calc(100% - 10px)',
      border: 'none',
      padding: 10,
      boxSizing: 'border-box',
      margin: 5,
    },
    '& > button[type=submit]': {
      background: ({ theme }) => theme.colorPrimaryLighter,
      color: ({ theme }) => theme.fontColorPrimary,
      cursor: 'pointer',
    },
    '& > button[type=submit]:hover': {
      background: ({ theme }) => theme.colorPrimary,
      color: ({ theme }) => theme.fontColorPrimary,
    }

  },
  header: {
    width: 'calc(100% + 40px)',
    background: ({ theme }) => theme.colorPrimary,
    color: '#fff',
    display: 'block',
    margin: '-21px -20px 10px',
    //textAlign: 'center',
    fontWeight: 'bold',
  },
  error: {
    color: ({ theme }) => theme.colorPrimary
  }
})

const Login = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<AuthError>();


  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    signInWithEmailAndPassword(getAuth(), email, password)
      .then(authUser => {
        //this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        //this.setState({ error });
        setError(error);
      });

    event.preventDefault();
  };

  const onEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    let v = event.currentTarget.value;
    setEmail(v);
  }
  const onPasswordChange = (event: React.FormEvent<HTMLInputElement>) => {
    let v = event.currentTarget.value;
    setPassword(v);
  }

  return (
    <form onSubmit={onSubmit} className={classes.root}>
      <span className={classes.header}>Login</span>
      <input
        name="email"
        value={email}
        onChange={onEmailChange}
        type="text"
        placeholder="Email Address"
      />
      <input
        name="password"
        value={password}
        onChange={onPasswordChange}
        type="password"
        placeholder="Password"
      />
      <button type="submit">Login</button>

      {error && <p className={classes.error}>{error.code} {error.message}</p>}
    </form>
  );
}

export default Login;