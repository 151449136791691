import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../../models";


type RuleNames = 'front' | 'title';
interface IProps {
    title: string;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    front: {
        position: 'absolute',
        background: '#313131',
        zIndex: 10,
        transform: 'translate3d(0,0,0.1px)',
        textAlign: 'center'
    },
    title: {
        color: '#fff',
        position: 'absolute',
        top: '50%',
        left: '50%',
        textAlign: 'center',
        transform: 'translate(-50%,-50%)',
        border: '1px solid #FC7100',
        width: 'fit-content',
        margin: 'auto',
        padding: 14
    }
})

const Front = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (<div className={classes.front}>
        <h1 className={classes.title}>{props.title}</h1>
    </div>);
}

export default Front;