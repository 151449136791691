import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import Button from "../button/Button";
import { ITheme } from "../../models";


type RuleNames = 'root';
interface IProps {
  onPageChange: any;
  count: number;
  page: number;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  root: {
    margin: "0 auto",
    width: "fit-content"
  }
})

const Pagination = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(0);
  const [items, setItems] = useState<JSX.Element[]>([]);
  useEffect(() => {
    let max = props.page + 1;
    let min = props.page - 1;
    if (min < 1) {
      min = 1;
    }
    if (max > props.count) {
      max = props.count;
    }

    var items = Array(max - min + 1).fill(null).map((i, index) => (
      <Button
        key={index}
        label={(index + min).toString()}
        color={index + min === props.page ? "primary" : "default"}
        inline
        minWidth={50}
        spacing={2}
        onClick={onPageClick(index + min)}
      />
    ));

    setItems(items);
    setMin(min);
    setMax(max);
    // eslint-disable-next-line
  }, [props.page, props.count]);

  const onPageClick = (newPage: number) => () => {
    if (props.onPageChange) {
      if (newPage > props.count) {
        newPage = props.count;
      }
      if (newPage < 1) {
        newPage = 1;
      }

      if (newPage !== props.page) {
        props.onPageChange(newPage, props.page);
      }
    }
  };

  return (
    <div className={classes.root}>
      <Button
        label="<<"
        inline
        minWidth={50}
        spacing={2}
        onClick={onPageClick(1)}
      />
      <Button
        label="<"
        inline
        minWidth={50}
        spacing={2}
        onClick={onPageClick(props.page - 1)}
      />
      {min !== 1 && <span>...</span>}
      {items}
      {max !== props.count && <span>...</span>}
      <Button
        label=">"
        inline
        minWidth={50}
        spacing={2}
        onClick={onPageClick(props.page + 1)}
      />
      <Button
        label=">>"
        inline
        minWidth={50}
        spacing={2}
        onClick={onPageClick(props.count)}
      />
    </div>
  );
}

export default Pagination;