import { createUseStyles, useTheme } from 'react-jss'
import Front from "./front/Front";
import Back from "./back/Back";
import { ITheme } from "../../models";


type RuleNames = 'card' | 'innerCard';
interface IProps {
    name: string;
    title: string;
    contacts: any;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    card: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%,-50%)',
        perspective: 500,
        '&:hover > div': {
            transform: 'rotate3d(0,1,0,180deg)'
        }
    },
    innerCard: {
        maxWidth: 500,
        width: '100vw',
        height: 300,
        position: 'relative',
        transform: 'rotate3d(0,1,0,0deg)',
        transformStyle: 'preserve-3d',
        transition: 'transform .4s linear',
        textTransform: 'uppercase',
        fontSize: 14,
        boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
        '& > div': {
            top: 0,
            left: 0,
            bottom: 0,
            right: 0
        }
    }
})

const Card = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (
        <div className={classes.card}>
            <div className={classes.innerCard}>
                <Front title={props.name} />
                <Back title={props.name} subtitle={props.title} contacts={props.contacts} />
            </div>
        </div>
    );
}

export default Card;