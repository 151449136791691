import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { IImage, IPortfolio, ITheme } from "../../models";
import Table from "../../components/table/Table";
import { Link } from "react-router-dom";
import { useImages, usePortfolio } from '../../hooks';
import { getApp } from '@firebase/app';
import { deleteDoc, doc, getFirestore, setDoc } from '@firebase/firestore';
import { getStorage, ref, deleteObject } from '@firebase/storage';

type RuleNames = 'root' | 'images' | 'action';
interface IProps {
    id?: string;
    item: IPortfolio
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {
        padding: 25,
        "& > span": {
            display: "block",
            width: "100%",
            maxWidth: 400,
            "& > *": {
                display: "block",
                width: "100%"
            }
        }
    },
    images: {},
    action: {
        textDecoration: "none",
        color: ({ theme }) => theme.colorPrimary,
        "&:visited": {
            color: ({ theme }) => theme.colorPrimary
        },
        "&:hover": {
            color: ({ theme }) => theme.colorPrimaryLighter
        },
        cursor: "pointer",
        margin: "0 5px"
    }
})

const ProjectImages = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });

    const images = useImages();
    const portfolios = usePortfolio();
    const [data, setData] = useState<IImage[]>([]);

    useEffect(() => {
        if (props.item.images) {
            let itemImages = props.item.images.map(i => i.id);
            var allImages = images.filter(f => itemImages.includes(f.id));
            setData(allImages);
        }
    }, [images, props.item.images])

    const deleteItem = (imageid: string) => async () => {
        let app = getApp();
        let db = getFirestore(app);

        let img = images.find(f => f.id === imageid);

        deleteDoc(doc(db, "images", imageid));

        if (img && img.imageRef) {
            let storage = getStorage();
            let fileRef = ref(storage, img.imageRef);
            deleteObject(fileRef);
        }

        if (props.id) {
            let portfolio = portfolios.find(f => f.id === props.id);
            if (portfolio) {
                if (portfolio.images) {
                    let imgRef = portfolio.images.find(f => f.id === imageid);
                    if (imgRef) {
                        let index = portfolio.images.indexOf(imgRef);
                        portfolio.images.splice(index, 1);
                        await setDoc(doc(db, "portfolio", props.id), portfolio);
                    }
                }

            }
        }
    };
    const columns = [
        {
            key: "id",
            label: "ID",
            width: 100
        },
        {
            key: "image",
            label: "Image",
            width: 300
        },
        {
            key: "actions",
            label: <Link to={`/backend/project/${props.id}/image`}>New image</Link>,
            width: 200,
            float: "right",
            customContent: (row: IImage) => {
                return (
                    <>
                        <Link to={`/backend/project/${props.id}/image/${row.id}`}>Edit</Link>
                        <span
                            className={classes.action}
                            onClick={deleteItem(row.id)}
                        >
                            Delete
                        </span>
                    </>
                );
            }
        }
    ];


    return (
        <div className={classes.images}>
            <Table columns={columns} data={data} />
        </div>
    );
}

export default ProjectImages;