import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";

type RuleNames = 'root';
interface IProps { }

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  root: {}
})

const Users = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  return <div className={classes.root}>Users</div>;
}

export default Users;