import { useState, useEffect } from 'react';
import { IGroup } from '../models';
import { getApp } from '@firebase/app';
import { collection, getFirestore, onSnapshot } from '@firebase/firestore';

const useGroups = () => {
  const [groups, setGroups] = useState<IGroup[]>([]);

  useEffect(() => {
    let app = getApp();
    let db = getFirestore(app);

    let portfolioRef = collection(db, "groups");

    return onSnapshot(portfolioRef, snapshot => {
      var items = snapshot.docs.map(doc => {
        let d = doc.data();
        d.id = doc.id;
        return d;
      }) as IGroup[];
      setGroups(items);
    });
  }, []);

  return groups;
}

export { useGroups };