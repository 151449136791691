import React from 'react';

interface IApp {
    isPortfolioExpanded: boolean;
    page: number;
    previousPage?: number;
    setPortfolioExpand?: (isExpanded: boolean) => void;
    togglePortfolioExpand?: () => void;
    setPage: (newPage: number) => void;
}

const AppContext = React.createContext<IApp>({ isPortfolioExpanded: false, page: 1, setPage: () => { } });
AppContext.displayName = "PilifApp";
const AppProvider = AppContext.Provider;
const AppConsumer = AppContext.Consumer;

export default AppContext;
export type { IApp };
export { AppProvider, AppConsumer };