import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import classNames from "classnames";
import { ITheme } from "../../models";


type RuleNames = 'item' | 'box' | 'title' | 'title1' | 'title2';
interface IProps {
  width?: number | string;
  color: string;
  onClick?: any;
  data: any;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({

  item: {
    position: "absolute",
    display: "block",
    verticalAlign: "top",
    width: "100%",
    margin: "auto",
    perspective: ({ ...props }) => props.width,
    top: "3.7vh"
  },
  box: {
    position: "relative",
    width: "100%",
    height: 60,
    marginLeft: "auto",
    marginRight: "auto",
    color: ({ ...props }) => props.color,
    textAlign: "center"
  },
  title: {
    position: "absolute",
    width: "fit-content",
    fontSize: 60,
    fontWeight: "bold",
    textTransform: "uppercase",
    left: "50%",
    transform: "translateX(-50%)",
    userSelect: "none"
  },
  title1: {
    color: ({ ...props }) => props.color
  },
  title2: {
    color: ({ ...props }) => props.color
  }
})

const Template = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  return (
    <div className={classes.item}>
      <div className={classes.box} onClick={props.onClick}>
        <span className={classNames(classes.title, classes.title1)}>
          {props.data.title}
        </span>
        {/* <span className={classNames(classes.title,classes.title2)}>{data.title}</span> */}
      </div>
    </div>
  );
}

export default Template;