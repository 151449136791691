import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'

import Portfolio from "../portfolio/Portfolio";
import Item from "./Item";
import Pagination from "../pagination/Pagination";
import { IGroup, IPortfolio, ITheme } from '../../models';
import { useWidth } from '../../hooks';



type RuleNames = 'root' | 'current' | 'beforeContent' | 'afterContent' | 'back';
interface IProps {
  color: string;
  color2: string;
  item: IGroup;
  portfolios: IPortfolio[];
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({

  root: {
    position: "relative",
    width: "70%",
    height: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    paddingTop: "50px"
  },
  current: {
    padding: "50px 0",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100%"
  },
  beforeContent: {
    position: "relative",
    margin: "15px auto",
    display: "block",
    width: "50px",
    height: "2px",
    background: ({ ...props }) => props.color2
  },
  afterContent: {
    position: "relative",
    margin: "15px auto",
    display: "block",
    width: "50px",
    height: "2px",
    background: ({ ...props }) => props.color2
  },
  back: {
    position: "absolute",
    width: "70vw",
    height: "80vh",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    background: ({ ...props }) => props.color,
    borderRadius: 20,
    boxShadow: "0 0 10px rgba(0,0,0,0.6)"
  }
})

const Info = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  const [page, setPage] = useState<number>(1);

  const { breakpoint } = useWidth();

  const [elements, setElements] = useState<number>(4);

  useEffect(() => {
    switch (breakpoint) {
      case "xs":
        setElements(1);
        break;
      case "sm":
        setElements(2);
        break;
      case "md":
        setElements(3);
        break;
      case "lg":
        setElements(3);
        break;
      case "xl":
        setElements(4);
        break;
      case "xxl":
        setElements(5);
        break;
    }
  }, [breakpoint]);

  const onPageChange = (newPage: number, oldPage: number) => {
    setPage(newPage);
  }

  return (<div className={classes.root}>
    <div className={classes.back} />
    <Item data={props.item} color={props.color} />
    <div className={classes.current}>
      <span className={classes.beforeContent}></span>
      <Portfolio
        items={props.portfolios.slice((page - 1) * elements, (page - 1) * elements + elements)}
        itemsPerPage={elements}
        group={props.item}
      />
      <span className={classes.afterContent}></span>
      {props.portfolios.length > elements && (
        <Pagination
          count={Math.ceil(props.portfolios.length / elements)}
          page={page}
          onPageChange={onPageChange}
        />
      )}
    </div>
  </div>);
}

export default Info;