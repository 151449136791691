import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'

import { IGroup, IPortfolio, ITheme } from '../../models';
import { useUser } from '../../hooks';
import { Link } from "react-router-dom";
import { SignOut } from "../auth";



type RuleNames = 'root';
interface IProps {
    color: string;
    color2: string;
    color3: string;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {
        position: "fixed",
        width: "100%",
        padding: 15,
        top: 0,
        background: props => props.color,
        color: props => props.color2,
        zIndex: 1,
        boxShadow: 'rgb(0 0 0 / 60%) 0px 0px 10px',
        "& a": {
            textDecoration: "none",
            color: props => props.color2,
            "&:visited": {
                color: props => props.color2
            },
            "&:hover": {
                color: props => props.color3
            }
        },
        "& *": {
            textDecoration: "none",
            color: props => props.color2
        }
    },
})

const Header = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    const user = useUser();

    return (<div className={classes.root}>
        <Link to="/backend">Backend</Link>
        <SignOut />
    </div>);
}

export { Header };