import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";
import Cell from './Cell';

import color from 'color';

type RuleNames = 'root';
interface IProps {
    columns: any[];
    data: any;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>(theme => ({
    root: {
        padding: 10,
        width: '100%',
        overflow: 'hidden',
        background: color(theme.colorPrimaryLighter).lighten(0.2).hsl().string(),
        color: theme.fontColorPrimary,
        maxHeight: 40,
        '&:nth-child(odd)': {
            background: color(theme.colorPrimaryLighter).lighten(0.25).hsl().string(),
            color: theme.fontColorPrimary,
        },
        '&:hover': {
            background: color(theme.colorPrimaryLighter).lighten(0.1).hsl().string(),
        },
        '&> span': {
            padding: '0 5px',
            display: 'inline-block',
            overflow: 'hidden',
            verticalAlign: 'top',
        }
    }
}))

const Row = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (
        <div className={classes.root}>
            {props.columns.map((col, index) => {
                var label = !!col.customContent ? col.customContent(props.data) : props.data[col.key];
                return (
                    <Cell key={index} label={label} width={col.width} float={col.float} />
                );
            })}
        </div>
    );
}

export default Row;