import { createUseStyles, useTheme } from 'react-jss'

import Page from "../components/page/Page";
import Card from "../components/card/Card";
import Info from "../components/info/Info";
import { Header } from "../components/header";
import PageContainer from "../components/page/PageContainer";
import Card1 from "../components/profile/card1/Card1";

import { ITheme } from '../models';
import { usePortfolio, useGroups, useGroupsToShow, useCardInfo, useUser } from '../hooks';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppContext from '../contexts/AppProvider';
import { Helmet } from 'react-helmet';


type RuleNames = 'root' | "@global";
interface IProps { }
interface IParams {
  group?: string;
  project?: string;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>(theme => ({
  "@global": {
    body: {
      overflow: "hidden"
    },
    "*": {
      boxSizing: "border-box"
    },
    a: {
      textDecoration: "none",
      color: theme.colorSecondary,
      "&:visited": {
        color: theme.colorSecondary
      }
    }
  },
  root: {
    width: "100%",
    height: "100%"
  }
}))

const Home = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const appContext = useContext(AppContext);
  const classes = useStyles({ ...props, theme });
  const items = usePortfolio();
  const groups = useGroups();
  const { groupsToShow, isLoading } = useGroupsToShow(groups, items);
  const user = useUser();
  const navigate = useNavigate();
  const cardInfos = useCardInfo();

  const { group, project } = useParams<keyof IParams>();

  const [initialGroup, setInitialGroup] = useState<string | undefined>(group);
  const [initialProject, setInitialProject] = useState<string | undefined>(project);

  const [pages, setPages] = useState<JSX.Element[]>([]);

  useEffect(() => {
    checkGroupPage();
  }, [group, groupsToShow]);

  useEffect(() => {
    let pgs: JSX.Element[] = [
      <Page color={theme && theme.colorSecondaryLighter}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Filip Andrei Muresan - Full stack developer</title>
          <link rel="cannonical" href={`${window.location.protocol}//${window.location.host}/`} />
        </Helmet>
        <Card {...cardInfos} />
      </Page>
    ];

    let psgGrps = groupsToShow.map((group, index) => {
      let portfolios = items.filter(f => f.group?.id === group.id);
      if (!portfolios || portfolios.length === 0) {
        return undefined;
      }
      return (
        <Page
          key={group.id}
          color={theme && (index % 2 !== 1
            ? theme.colorPrimary
            : theme.colorSecondaryLighter)
          }
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>{`Filip Andrei Muresan - ${group.title}`}</title>
            <link rel="cannonical" href={`${window.location.protocol}//${window.location.host}/${group.slug}`} />
          </Helmet>
          <Info
            item={group}
            portfolios={portfolios}
            color={theme && (
              index % 2 !== 0
                ? theme.colorPrimary
                : theme.colorSecondaryLighter)
            }
            color2={theme && (
              index % 2 !== 1
                ? theme.colorPrimary
                : theme.colorSecondaryLighter)
            }
          />
        </Page>
      );
    }).filter(t => t !== undefined) as JSX.Element[];

    setPages([...pgs, ...psgGrps]);
  }, [theme.colorSecondaryLighter, cardInfos, groupsToShow, setPages]);

  useEffect(() => {
    if (!appContext.previousPage) {
      return;
    }
    if (appContext.previousPage === appContext.page) {
      return;
    }
    if (group && project) {
      return;
    }
    if (appContext.page === 1) {
      //home
      navigate(`/`);
    }
    else {
      //portfolio
      let index = appContext.page - 2;
      let grp = groupsToShow[index];
      if (grp) {
        navigate(`/${grp.slug}`);
      }
    }
  }, [appContext.previousPage, appContext.page, group, project]);

  const checkGroupPage = () => {
    if (group) {
      let grp = groupsToShow.find(i => i.slug === group.toLowerCase());
      if (grp) {
        let index = groupsToShow.indexOf(grp);
        let newPage = index + 2;
        appContext.setPage(newPage);
      }
    }
  }
  if (isLoading) {
    return <div>LOADING...</div>
  }
  return (
    <div className={classes.root}>

      {user && <Header color={theme && (appContext.page % 2 !== 0
        ? theme.colorPrimary
        : theme.colorSecondaryLighter)}
        color2={theme && (appContext.page % 2 !== 1
          ? theme.colorPrimary
          : theme.colorSecondary)}
        color3={theme && (appContext.page % 2 !== 1
          ? theme.colorPrimaryLighter
          : theme.colorSecondaryLighter)} />}
      <PageContainer pages={pages} />
    </div>
  );
}

export default Home;