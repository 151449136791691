import { useState, useEffect } from 'react';

type Breakpoint = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";

const useWidth = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const [breakpoint, setBreakpoint] = useState<Breakpoint>("lg");

    useEffect(() => {

        const handleBreakpoint = (w: number) => {
            if (w < 576) {
                setBreakpoint("xs");
            }
            else if (w < 768) {
                setBreakpoint("sm");
            }
            else if (w < 992) {
                setBreakpoint("md");
            }
            else if (w < 1200) {
                setBreakpoint("lg");
            }
            else if (w < 1400) {
                setBreakpoint("xl");
            }
            else {
                setBreakpoint("xxl");
            }
        }

        const handelResize = () => {
            setWidth(window.innerWidth);
            handleBreakpoint(window.innerWidth);
        }




        window.addEventListener('resize', handelResize);
        handelResize();
        return () => {
            window.removeEventListener('resize', handelResize);
        }
    }, []);

    return { width, breakpoint };
}

export { useWidth };
export type { Breakpoint };