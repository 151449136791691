import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { IGroup, IPortfolio, ITheme } from '../../models';
import PortfolioItem from "./PortfolioItem";


type RuleNames = 'root';
interface IProps {
  items: IPortfolio[];
  group: IGroup;
  itemsPerPage: number;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  root: {
    textAlign: "center"
  }
})

const Portfolio = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  return <div className={classes.root}>{props.items.map((item) => {
    return <PortfolioItem key={item.id} item={item} itemsPerPage={props.itemsPerPage} group={props.group} />;
  })}</div>;
}

export default Portfolio;