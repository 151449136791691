import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";

type RuleNames = 'root';
interface IProps {
    image?: string;
    title: string;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {
        backgroundImage: props => props.image ? `url(${props.image})` : 'initial',
        backgroundColor: props => props.theme.colorSecondary,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '100%',
        paddingTop: '66%'
    }
})

const PortfolioThumbnail = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (<div className={classes.root}></div>);
}

export default PortfolioThumbnail;