import { useState, useRef } from 'react';

const compare = (item1: any,item2: any):number  =>{
    if(item1.id < item2.id){
      return -1;
    }
    if(item1.id > item2.id){
      return 1;
    }
    return 0;
  }
  const compareDesc = (item2: any,item1: any): number =>{
    if(item1.id < item2.id){
      return -1;
    }
    if(item1.id > item2.id){
      return 1;
    }
    return 0;
  }

  function useReferredState<T>(initialValue: T): [T, React.MutableRefObject<T>, React.Dispatch<T>]{
    const [state, setState] = useState<T>(initialValue);
    const reference = useRef<T>(state);

    const setReferredState = (value: T) => {
        reference.current = value;
        setState(value);
    };

    return [state, reference, setReferredState];
  }

  export { compare, compareDesc, useReferredState };