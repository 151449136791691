import { ICardInfo } from '../models';

import LinkedIn from "mdi-react/LinkedinIcon";
import GitHub from "mdi-react/GithubIcon";
import Codepen from "mdi-react/CodepenIcon";
import Email from "mdi-react/EmailOutlineIcon";
import { useState } from 'react';

const useCardInfo = () => {
    const [cardInfos, setCardInfos] = useState<ICardInfo>({
        name: "Filip Andrei Muresan",
        title: "Full stack developer",
        profilePicture: "https://picsum.photos/id/565/100/100",
        contacts: [
            {
                label: "LinkedIn",
                icon: <LinkedIn />,
                link: "https://linkedin.com/in/filip-andrei-muresan/",
                text: "Visit my profile"
            },
            {
                label: "GitHub",
                icon: <GitHub />,
                link: "https://github.com/filipandry",
                text: "filipandry"
            },
            {
                label: "Codepen",
                icon: <Codepen />,
                link: "https://codepen.io/filip_andry/",
                text: "@filip_andry"
            },
            {
                label: "Email",
                icon: <Email />,
                link: "mailto:info@pilif.eu",
                text: "info@pilif.eu"
            }
        ]
    })
    return cardInfos;
}

export { useCardInfo };