/*import React, { Component } from 'react';
import injectSheet from 'react-jss'

const styles = theme => ({
    root: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: props => props.width || 150,
        float: props => props.float || 'unset',
    }
});

class Cell extends Component {
    render() {
        var { classes, label, component } = this.props;
        if(!component){
            component = <span />;
        }
        return (
            <component className={classes.root}>
                {label}
            </component>
        );
    }
}

export default injectSheet(styles)(Cell);*/

import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../models";

type RuleNames = 'root';
interface IProps {
    label: any;
    width?: number | string;
    float?: number | string;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {
        display: 'inline-block',
        verticalAlign: 'top',
        width: props => props.width || 150,
        float: props => props.float || 'unset',
    }
})

const Cell = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    return (
        <span className={classes.root}>
            {props.label}
        </span>
    );
}

export default Cell;