import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { IGroup, ITheme } from "../../models";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useGroups, usePortfolio } from '../../hooks';
import { doc, getFirestore, setDoc } from '@firebase/firestore';
import { getApp } from '@firebase/app';
import { getNextId } from '../../components/firebase/Firebase';
import ProjectImages from './ProjectImages';

type RuleNames = 'root' | 'action';
interface IProps { }
interface IParams {
    id?: string;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {
        padding: 25,
        "& > span": {
            display: "block",
            width: "100%",
            maxWidth: 400,
            "& > *": {
                display: "block",
                width: "100%"
            }
        }
    },
    action: {
        textDecoration: "none",
        color: ({ theme }) => theme.colorPrimary,
        "&:visited": {
            color: ({ theme }) => theme.colorPrimary
        },
        "&:hover": {
            color: ({ theme }) => theme.colorPrimaryLighter
        },
        cursor: "pointer",
        margin: "0 5px"
    }
})

const Group = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    //const history = useHistory();
    const navigate = useNavigate();
    const [item, setItem] = useState<IGroup>({ id: '', title: '', slug: '' });
    const groups = useGroups();
    const { id } = useParams<keyof IParams>();

    useEffect(() => {
        if (id) {
            let grp = groups.find(f => f.id === id);
            if (grp) {
                setItem(grp);
            }
        }
    }, [id, groups])

    const onChange = (key: keyof IGroup) => (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
        var value = ev.currentTarget.value;
        setItem(state => {
            state[key] = value;
            if (key === "title") {
                state.slug = state.title.replaceAll(' ', '-').toLowerCase();
            }
            return { ...state };
        })
        //setItem({ ...item, [key]: value });
    };
    const onSave = async () => {
        let db = getFirestore(getApp());
        //item.group = doc(db, "groups", item.group as string);
        let locID = id;
        if (!locID) {
            locID = await getNextId("groups");
        }
        await setDoc(doc(db, "groups", locID), item);


        if (!id) {
            navigate(`/backend/group/${locID}`, { replace: true });
        }
    };

    return (
        <div className={classes.root}>
            <Link to="/backend/groups">Back</Link>
            <span>
                <label htmlFor="title">Title</label>
                <input
                    type="text"
                    name="title"
                    id="title"
                    value={item?.title}
                    onChange={onChange("title")}
                />
            </span>
            <span>
                <label htmlFor="slug">Slug</label>
                <input
                    type="text"
                    name="slug"
                    id="slug"
                    value={item?.slug}
                    onChange={onChange("slug")}
                />
            </span>
            <span className={classes.action} onClick={onSave}>
                SAVE
            </span>
        </div>
    );
}

export default Group;