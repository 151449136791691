import React, { useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "./models";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Backend from "./pages/Backend";
import PageNotFound from "./pages/PageNotFound";
import smoothscrool from 'smoothscroll-polyfill';
import { AppProvider, IApp } from './contexts/AppProvider';

type RuleNames = '@global';
interface IProps { }

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  '@global': {
    "*": {
      boxSizing: 'border-box'
    },
    body: {
      margin: 0,
      padding: 0,
      fontFamily: ' -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif',
      MozOsxFontSmoothing: 'grayscale',
      WebkitFontSmoothing: 'antialiased'
    },
    code: {
      fontFamily: 'source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace'
    }
  }
})

smoothscrool.polyfill();

const App = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const [app, setApp] = useState<IApp>({ isPortfolioExpanded: false, page: 1, setPage: () => { } });
  useStyles({ ...props, theme });

  useEffect(() => {
    setApp(state => {
      state.setPortfolioExpand = setPortfolioExpand;
      state.setPage = setPage;
      return { ...state };
    })
  }, []);

  const setPage = (newPage: number) => {
    setApp(state => {
      state.previousPage = state.page;
      state.page = newPage;

      return { ...state };
    })
  }
  const setPortfolioExpand = (isExpanded: boolean) => {
    setApp(state => {
      state.isPortfolioExpanded = isExpanded;

      return { ...state };
    });
  }
  return (
    <AppProvider value={app}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}>
            <Route path=":group" element={<Home />}>
              <Route path=":project" element={<Home />} />
            </Route>
          </Route>
          <Route path="backend" element={<Backend />}>
            <Route path="groups" />
            <Route path="group">
              <Route path=":id" />
            </Route>
            <Route path="projects" />
            <Route path="project">
              <Route path=":id">
                <Route path="image">
                  <Route path=":id" />
                </Route>
              </Route>
            </Route>
            <Route path="users" />
          </Route>

          {/* <Route element={<PageNotFound />} /> */}
        </Routes>
      </Router>
    </AppProvider>
  );
}

export default App;