import React, { useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { useUser } from '../../hooks';
import { ITheme } from "../../models";

import { getAuth, signOut, AuthError } from '@firebase/auth';

type RuleNames = 'root' | 'button';
interface IProps { }

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  root: {
    float: 'right',
    color: ({ theme }) => theme.fontColorSecondary
  },
  button: {
    margin: '0 5px',
    textDecoration: 'none',
    color: ({ theme }) => theme.colorPrimary,
    cursor: 'pointer',
    '&:visited': {
      color: ({ theme }) => theme.colorPrimary,
    },
    '&:hover': {
      color: ({ theme }) => theme.colorPrimaryLighter,
    }
  }
})

const SignOut = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  const user = useUser();

  const [error, setError] = useState<AuthError>();



  const onLogout = () => {
    signOut(getAuth())
      .catch(error => {
        setError(error);
      });
  };

  return (
    <div className={classes.root}>
      <span>{user?.displayName || user?.email}</span>
      <span className={classes.button} onClick={onLogout}>Logout</span>
      {error && <p>{error.message}</p>}
    </div>
  );
}

export default SignOut;