import { useState, useEffect } from 'react';
import { IPortfolio } from '../models';
import { getApp } from '@firebase/app';
import { collection, getFirestore, onSnapshot } from '@firebase/firestore';

const usePortfolio = () => {
  const [portfolio, setPortfolio] = useState<IPortfolio[]>([]);

  useEffect(() => {
    let app = getApp();
    let db = getFirestore(app);

    let portfolioRef = collection(db, "portfolio");

    return onSnapshot(portfolioRef, snapshot => {
      var items = snapshot.docs.map(doc => {
        let d = doc.data();
        //d.group = d.group.id;
        d.id = doc.id;
        return d;
      }) as IPortfolio[];

      setPortfolio(items);
    });
  }, []);

  return portfolio;
}

export { usePortfolio };