import React, { useContext, useEffect, useRef, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import ReactDOM from "react-dom";
import classNames from "classnames";
import Pagination from "../pagination/Pagination";
import { IGroup, IImage, IPortfolio, ITheme } from '../../models';
import { Breakpoint, useImages } from '../../hooks';
import { Helmet } from 'react-helmet';

import Button from '../button/Button';
import AppContext from '../../contexts/AppProvider';


type RuleNames = 'root' | 'rootBefore' | 'rootAfter' | 'left' | 'right' | 'title' | 'gallery' | 'mainImage' | 'thumbnails' | 'thumbnail' | 'thumbailActive' | 'bottomActions' | 'content' | 'bottomLeftActions';
interface IProps {
  top: number;
  left: number;
  width: number;
  height: number;
  heightEnd: number;
  widthEnd: number;
  data: IPortfolio;
  group: IGroup;
  onClose: () => void;
  breakpoint: Breakpoint;
  isLoggedIn: boolean;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  root: {
    position: "absolute",
    background: ({ theme }) => theme.colorSecondary,
    transition: "all 0.3s linear",
    boxShadow: "0px 0px 10px rgba(0,0,0,0.7)",
    fontSize: 0,
    "&> div": {
      opacity: 0,
      transition: "opacity 0.15s linear"
    }
  },
  rootBefore: {
    top: (props) => props.top,
    left: (props) => props.left,
    width: (props) => props.width,
    height: (props) => props.height
  },
  rootAfter: {
    top: ({ ...props }) =>
      window.scrollY + window.innerHeight / 2 - props.heightEnd / 2 + (props.isLoggedIn ? 20 : 0),
    left: ({ ...props }) =>
      window.scrollX + window.innerWidth / 2 - props.widthEnd / 2,
    width: ({ ...props }) => props.widthEnd,
    height: ({ ...props }) => props.heightEnd,
    "&> div": {
      opacity: 1,
      transition: "opacity 0.3s linear"
    }
  },
  left: {
    display: "inline-block",
    width: ({ breakpoint }) => {
      switch (breakpoint) {
        case "xs":
        case "sm":
        case "md":
          return "100%";
        case "lg":
        case "xl":
        case "xxl":
          return "65%";
      }
    },
    height: ({ breakpoint }) => {
      switch (breakpoint) {
        case "xs":
        case "sm":
        case "md":
          return "70%";
        case "lg":
        case "xl":
        case "xxl":
          return "100%";
      }
    },
    verticalAlign: "top",
    fontSize: "initial",
    padding: 10
  },
  right: {
    display: "inline-block",
    width: ({ breakpoint }) => {
      switch (breakpoint) {
        case "xs":
        case "sm":
        case "md":
          return "100%";
        case "lg":
        case "xl":
        case "xxl":
          return "35%";
      }
    },
    height: ({ breakpoint }) => {
      switch (breakpoint) {
        case "xs":
        case "sm":
        case "md":
          return "30%";
        case "lg":
        case "xl":
        case "xxl":
          return "100%";
      }
    },
    background: ({ theme }) => theme.colorPrimaryLighter,
    verticalAlign: "top",
    fontSize: "initial",
    padding: 10
  },
  title: {
    fontSize: 40,
    fontWeight: "bold",
    marginBlockStart: 0,
    marginBlockEnd: 0,
    marginInlineStart: 0,
    marginInlineEnd: 0,
  },
  gallery: {},
  mainImage: {
    width: "100%",
    //paddingTop: "56.25%",
    background: ({ theme }) => theme.colorPrimary,
    height: ({ ...props }) => {
      let multiplier = 1;
      switch (props.breakpoint) {
        case "xs":
        case "sm":
        case "md":
          multiplier = 0.7;
          break;
        case "lg":
        case "xl":
        case "xxl":
          multiplier = 1;
          break;
      }
      return (props.heightEnd * multiplier) * 0.7;
    }
  },
  thumbnails: {
    fontSize: 0,
    paddingTop: 10,
    height: ({ ...props }) => {
      let multiplier = 1;
      switch (props.breakpoint) {
        case "xs":
        case "sm":
        case "md":
          multiplier = 0.7;
          break;
        case "lg":
        case "xl":
        case "xxl":
          multiplier = 1;
          break;
      }
      return (props.heightEnd * multiplier) * 0.3;
    }
  },
  thumbnail: {
    width: "calc(25% - 10px)",
    margin: 5,
    display: "inline-block",
    verticalAlign: "top",
    paddingTop: "14.0625%",
    background: ({ theme }) => theme.colorPrimary,
    cursor: "pointer"
  },
  thumbailActive: {
    border: ({ theme }) => `2px solid ${theme.colorPrimary}`,
    paddingTop: "calc(14.0625% - 4px)"
  },
  bottomActions: {
    position: 'absolute',
    bottom: 10,
    right: 10
  },
  content: {
    whiteSpace: 'break-spaces',
    overflowY: 'auto',
    height: (props) => {
      switch (props.breakpoint) {
        case "xs":
        case "sm":
        case "md":
          return 110;
        case "lg":
        case "xl":
        case "xxl":
        default:
          return '100%';
      }
    }
  },
  bottomLeftActions: {
    position: 'absolute',
    bottom: 10,
    left: 10
  }
})

const ExpandingPanel = (props: IProps) => {
  const appContext = useContext(AppContext);

  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  const container = useRef<HTMLDivElement>(null);

  const [images, setImages] = useState<IImage[]>([]);
  const [allImages, setAllImages] = useState<IImage[]>([]);
  const [imageid, setImageid] = useState<string | undefined>(undefined);
  const [thumbnailsPage, setThumbnailsPage] = useState<number>(1);
  const [mainImage, setMainImage] = useState<string | undefined>(undefined);
  const [start, setStart] = useState<boolean>(false);
  const dbImages = useImages();


  useEffect(() => {
    setTimeout(() => setStart(true), 10);
    document.addEventListener("mousedown", handleClickOutside);
    appContext.setPortfolioExpand?.call(appContext, true);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      appContext.setPortfolioExpand?.call(appContext, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {

    if (props.data.images) {
      //var allImages = props.data.images?.filter((f) => f !== undefined);
      let itemImages = props.data.images.map(i => i.id);
      var allImages = dbImages.filter(f => itemImages.includes(f.id));

      let startT = (thumbnailsPage - 1) * 4;
      let endT = startT + 4;
      let images = !!allImages ? allImages.slice(startT, endT) : [];

      if (imageid) {
        let mImg = allImages.find(f => f.id === imageid);
        setMainImage(mImg?.image);
      } else {
        if (allImages && allImages.length > 0) {
          setMainImage(allImages[0].image);
          setImageid(allImages[0].id);
        }
      }


      setAllImages(allImages);
      setImages(images);
    }

  }, [props.data.images, imageid, thumbnailsPage, dbImages])


  const thumbnailClick = (id: string) => () => {
    setImageid(id);
  }
  const thumbnailsPageChange = (page: number) => {
    setThumbnailsPage(page);
  }

  const doClose = () => {
    setStart(false);

    setTimeout(() => {
      props.onClose && props.onClose();
    }, 300);
  };
  const handleClickOutside = (event: any) => {
    if (
      container.current &&
      !container.current.contains(event.target)
    ) {
      doClose();
    }
  };
  const onClose = () => {
    doClose();
  }

  return ReactDOM.createPortal(
    <div
      ref={container}
      className={classNames(classes.root, {
        [classes.rootBefore]: !start,
        [classes.rootAfter]: start
      })}
    >
      <Helmet>
        <title>{`Filip Andrei Muresan - ${props.data.title}`}</title>
        <link rel="cannonical" href={`${window.location.protocol}//${window.location.host}/${props.group.slug}/${props.data.slug}`} />
      </Helmet>
      <div className={classes.left}>
        <div className={classes.gallery}>
          <div
            className={classes.mainImage}
            style={{ background: `url(${mainImage}) center/cover no-repeat` }}
          ></div>
          <div className={classes.thumbnails}>
            {images.map((img, index) => (
              <div
                key={index}
                className={classNames(classes.thumbnail, {
                  [classes.thumbailActive]: img.id === imageid
                })}
                style={{
                  background: `url(${img.image}) center/cover no-repeat`
                }}
                onClick={thumbnailClick(img.id)}
              ></div>
            ))}
            {allImages.length > 4 && (
              <Pagination
                count={Math.ceil(allImages.length / 4)}
                page={thumbnailsPage}
                onPageChange={thumbnailsPageChange}
              />
            )}
          </div>
        </div>
      </div>
      <div className={classes.right}>
        <h1 className={classes.title}>{props.data.title}</h1>
        <p className={classes.content}>{props.data.text}</p>
        <div className={classes.bottomLeftActions}>
          {props.data.link && <Button label={props.data.linkLabel ?? "PREVIEW"} link={props.data.link} target="_blank" />}
        </div>
        <div className={classes.bottomActions}>
          <Button label="Close item" onClick={onClose} />
        </div>
      </div>
    </div>,
    document.body
  );
}

export default ExpandingPanel;