import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { IPortfolio, ITheme } from "../../models";
import Table from "../../components/table/Table";
import GroupViewer from "./GroupViewer";
import { Link } from "react-router-dom";
import { usePortfolio } from '../../hooks';
import { doc, getFirestore, deleteDoc } from '@firebase/firestore';
import { getApp } from '@firebase/app';

type RuleNames = 'root' | 'action';
interface IProps { }

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  root: {
    padding: 25
  },
  action: {
    textDecoration: "none",
    color: ({ theme }) => theme.colorPrimary,
    "&:visited": {
      color: ({ theme }) => theme.colorPrimary
    },
    "&:hover": {
      color: ({ theme }) => theme.colorPrimaryLighter
    },
    cursor: "pointer",
    margin: "0 5px"
  }
})

const Projects = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  const portfolio = usePortfolio();

  const deleteItem = (id: string) => () => {
    let app = getApp();
    let db = getFirestore(app);
    deleteDoc(doc(db, "portfolio", id));
    //firebase.db.ref(`/portfolio/${id}`).set(null);
  };

  const columns = [
    {
      key: "id",
      label: "ID",
      width: 100
    },
    {
      key: "title",
      label: "Title",
      width: 200
    },
    {
      key: "slug",
      label: "Slug",
      width: 200
    },
    {
      key: "text",
      label: "Text",
      width: 400
    },
    {
      key: "group",
      label: "Group",
      width: 100,
      customContent: (row: IPortfolio) => <GroupViewer id={row.group?.id} />
    },
    {
      key: "actions",
      label: <Link to={`/backend/project/`}>New project</Link>,
      width: 200,
      float: "right",
      customContent: (row: IPortfolio) => {
        return (
          <>
            <Link to={`/backend/project/${row.id}`}>Edit</Link>
            <span
              className={classes.action}
              onClick={deleteItem(row.id)}
            >
              Delete
            </span>
          </>
        );
      }
    }
  ];


  return (
    <div className={classes.root}>
      <Table columns={columns} data={portfolio} />
    </div>
  );
}

export default Projects;