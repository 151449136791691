import React, { useCallback, useContext, useEffect, useState } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import PageIndicator from "./PageIndicator";
import { useReferredState } from '../../utils';
import { ITheme } from "../../models";
import AppContext from '../../contexts/AppProvider';

type RuleNames = 'root';
interface IProps {
  //children: any;
  pages: JSX.Element[];
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  root: {}
})

const PageContainer = (props: IProps) => {
  const appContext = useContext(AppContext);
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });

  //const [_, pageRef, setPage] = useReferredState<number>(appContext.page);
  const [pages, pagesRef, setPages] = useReferredState<number>(0);
  const [touchY, touchYRef, setTouchY] = useReferredState<number>(0);
  const [isScrolling, isScrollingRef, setIsScrolling] = useReferredState<boolean>(false);
  const [isTouching, isTouchingRef, setIsTouching] = useReferredState<boolean>(false);


  const [showPagination, showPaginationRef, setShowPagination] = useReferredState<boolean>(false);
  useEffect(() => {
    scroll(appContext.page);
  }, [appContext.page]);
  useEffect(() => {
    onResize();
    let cPage = getCookie("page");
    if (cPage) {
      appContext.setPage(parseInt(cPage, 10));
    } else {
      scroll(appContext.page);
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    var passive = { passive: false };
    document.addEventListener("touchstart", onTouchStart, passive);
    document.addEventListener("touchend", onTouchEnd, passive);
    document.addEventListener("touchmove", onTouchMove, passive);
    document.addEventListener("wheel", onWeel, passive);
    window.addEventListener("resize", onResize);
    return () => {

      document.removeEventListener("touchstart", onTouchStart);
      document.removeEventListener("touchend", onTouchEnd);
      document.removeEventListener("touchmove", onTouchMove);
      document.removeEventListener("wheel", onWeel);
      window.removeEventListener("resize", onResize);
    }
    // eslint-disable-next-line
  }, [appContext.page])
  useEffect(() => {
    let pages = props.pages.length;
    // if (props.pages) {

    //   // if (Array.isArray(props.children)) {
    //   //   pages = props.children.length;
    //   //   var delta = 0;
    //   //   for (var i = 0; i < pages; i++) {
    //   //     if (Array.isArray(props.children[i])) {
    //   //       delta += props.children[i].length - 1;
    //   //     }
    //   //   }
    //   //   pages += delta;
    //   // } else {
    //   //   pages = 1;
    //   // }
    // }

    setPages(pages);
    // eslint-disable-next-line
  }, [props.pages]);

  useEffect(() => {
    setShowPagination(pages > 1 && !appContext.isPortfolioExpanded);
    // eslint-disable-next-line
  }, [pages, appContext.isPortfolioExpanded])

  const getCookie = (cname: string) => {
    var name = cname + "=";
    var decodedCookie = document.cookie; //decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };
  const onResize = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
    scroll(appContext.page);
  };
  const onTouchStart = (event: any) => {
    setTouchY(event.touches[0].screenY);
    setIsTouching(true);
  };
  const onTouchEnd = (event: any) => {
    setTouchY(0);
    setIsTouching(false);
  };
  const onTouchMove = (event: any) => {
    if (showPaginationRef.current) {

      event.preventDefault();
      event.returnValue = false;

      var maxPages = pagesRef.current;
      if (!isTouchingRef.current) {
        return;
      }
      if (isScrollingRef.current) {
        return;
      }

      var delta = event.touches[0].screenY - touchYRef.current;
      var deltaAbs = Math.abs(delta);
      console.log([delta, deltaAbs]);
      if (deltaAbs < 100) {
        return;
      }
      // if (delta <= 100 && delta >= -100) {
      //   return;
      // }
      let locPage = appContext.page;
      setIsScrolling(true);
      setIsTouching(false);
      if (delta > 100) {
        locPage--;
      } else if (delta < -100) {
        locPage++;
      }

      if (locPage < 0) {
        locPage = 0;
      }
      if (locPage > maxPages) {
        locPage = maxPages;
      }

      appContext.setPage(locPage);
    }
  };
  const onWeel = useCallback((event: any) => {
    console.log(appContext.page);
    if (showPaginationRef.current) {

      event.preventDefault();
      event.returnValue = false;

      var maxPages = pagesRef.current;
      if (isScrollingRef.current) {
        return;
      }
      setIsScrolling(true);
      let locPage = appContext.page;
      if (event.deltaY > 0) {
        locPage++;
      } else if (event.deltaY < 0) {
        locPage--;
      }
      if (locPage < 1) {
        locPage = 1;
      }
      if (locPage > maxPages) {
        locPage = maxPages;
      }
      if (locPage === appContext.page) {
        setIsScrolling(false);
      }
      else {
        appContext.setPage(locPage);
      }
    }
  }, [appContext.page, showPaginationRef.current, pagesRef.current, isScrollingRef.current]);
  const onPageUp = () => {
    if (!showPaginationRef.current) {
      return;
    }
    let tPage = appContext.page - 1;
    if (tPage < 1) {
      tPage = 1;
    }
    appContext.setPage(tPage);
  };
  const onPageDown = () => {
    if (!showPaginationRef.current) {
      return;
    }
    var maxPages = pagesRef.current;
    let tPage = appContext.page + 1;
    if (tPage >= maxPages) {
      tPage = maxPages;
    }
    appContext.setPage(tPage);
  };
  const scroll = (page: number) => {
    // debugger;
    // if (!showPaginationRef.current) {
    //   return;
    // }
    //window.scroll({ top: (page - 1) * window.innerHeight, behavior: "smooth" });

    document.cookie = "page=" + page;


    setTimeout(() => {
      setIsScrolling(false);
    }, 500);
  };

  return (
    <div className={classes.root}>
      {props.pages[appContext.page - 1]}
      {showPagination && <PageIndicator
        onPageUp={onPageUp}
        onPageDown={onPageDown}
      />}
    </div>
  );
}

export default PageContainer;