import { createUseStyles, useTheme } from 'react-jss'
import { ITheme } from "../../../models";


type RuleNames = 'back' | 'name' | 'subtitle' | 'contacts' | 'contact' | 'contactLabel';
interface IContact {
  label: string,
  link: string,
  text: string
}
interface IProps {
  title: string,
  subtitle: string,
  contacts: IContact[]
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
  back: {
    position: 'absolute',
    background: '#FC7100',
    zIndex: 10,
    transform: 'translate3d(0,0,0px) rotateY(180deg)',
    padding: 10,
    color: '#313131',
    '& a': {
      color: '#313131',
      textDecoration: 'unset',
    }
  },
  name: {
    fontWeight: 'bold',
    fontSize: 18,
    margin: [5, 0]
  },
  subtitle: {
    color: '#fff',
    fontSize: 14,
    margin: [5, 0]
  },
  contacts: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    top: 'unset',
    padding: 10
  },
  contact: {
    margin: 0
  },
  contactLabel: {
    fontWeight: 'bold',
    color: '#fff',
    display: 'inline-block',
    width: 80
  },
})

const Template = (props: IProps) => {
  const theme = useTheme<ITheme>();
  const classes = useStyles({ ...props, theme });
  return (
    <div className={classes.back}>
      <h2 className={classes.name}>{props.title}</h2>
      <p className={classes.subtitle}>{props.subtitle}</p>
      <div className={classes.contacts}>
        {props.contacts.map((item, index) => (
          <p key={index} className={classes.contact}>
            <span className={classes.contactLabel}>{item.label}:</span>{" "}
            <a href={item.link}>{item.text}</a>
          </p>
        ))}
      </div>
    </div>
  );
}

export default Template;