import React, { useRef, forwardRef, useImperativeHandle, Ref } from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'; import { ITheme } from "../../models";


type RuleNames = 'root' | 'fullWidth' | 'inline';
interface IProps {
    id?: string;
    spacing?: number;
    marginTop?: number;
    color?: 'default' | 'primary' | 'secondary';
    minWidth?: number | string;
    fullWidth?: boolean;
    inline?: boolean;
    label: string;
    onClick?: React.MouseEventHandler<HTMLSpanElement>;
    link?: string;
    target?: React.HTMLAttributeAnchorTarget;
    className?: string;
}

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({

    root: {
        display: 'block',
        marginLeft: props => props.spacing || 'auto',
        marginRight: props => props.spacing || 'auto',
        marginTop: props => props.marginTop || 0,
        textAlign: 'center',
        padding: 8,
        background: ({ theme, ...props }) => props.color === "primary" ? theme.colorPrimary : theme.colorSecondary,
        border: ({ theme }) => "1px solid " + theme.colorSecondary,
        color: ({ theme, ...props }) => props.color === "primary" ? theme.fontColorPrimary : theme.fontColorSecondary,
        fontSize: 14,
        cursor: 'pointer',
        '&:hover': {
            background: ({ theme, ...props }) => props.color === "primary" ? theme.colorPrimaryLighter : theme.colorSecondaryLighter
        },
        minWidth: ({ ...props }) => props.minWidth || "initial",
        width: 'fit-content',
    },
    fullWidth: {
        width: '90%',
    },
    inline: {
        display: "inline-block",
    }
})

interface IBound {
    top: number;
    left: number;
    width: number;
    height: number;
}
interface IRef {
    getBounds: () => IBound | void
}

const Button = forwardRef((props: IProps, ref: Ref<IRef>) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });

    const span = useRef<HTMLElement>(null);

    useImperativeHandle(ref, () => ({
        getBounds
    }));

    const getCoords = (elem: HTMLElement): IBound => { // crossbrowser version
        var box = elem.getBoundingClientRect();
        var body = document.body;
        var docEl = document.documentElement;

        var scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
        var scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft;

        var clientTop = docEl.clientTop || body.clientTop || 0;
        var clientLeft = docEl.clientLeft || body.clientLeft || 0;

        var top = box.top + scrollTop - clientTop;
        var left = box.left + scrollLeft - clientLeft;

        return { top: Math.round(top), left: Math.round(left), width: box.width, height: box.height };
    }
    const getBounds = (): IBound | void => {
        if (span.current) {
            return getCoords(span.current);
        }
    }
    if (props.link) {
        return (
            <a className={props.className} href={props.link} target={props.target}><span ref={span} id={props.id} className={classNames(classes.root, { [classes.fullWidth]: props.fullWidth, [classes.inline]: props.inline })}>
                {props.label}
            </span>
            </a>
        );
    }
    return (
        <span ref={span} id={props.id} className={classNames(classes.root, props.className, { [classes.fullWidth]: props.fullWidth, [classes.inline]: props.inline })} onClick={props.onClick}>
            {props.label}
        </span>
    );
})

export default Button;