import React from 'react';
import { createUseStyles, useTheme } from 'react-jss'
import { IGroup, ITheme } from "../../models";
import Table from "../../components/table/Table";
import { Link } from "react-router-dom";
import { useGroups, usePortfolio } from '../../hooks';
import { doc, getFirestore, deleteDoc } from '@firebase/firestore';
import { getApp } from '@firebase/app';

type RuleNames = 'root' | 'action';
interface IProps { }

const useStyles = createUseStyles<RuleNames, IProps, ITheme>({
    root: {
        padding: 25
    },
    action: {
        textDecoration: "none",
        color: ({ theme }) => theme.colorPrimary,
        "&:visited": {
            color: ({ theme }) => theme.colorPrimary
        },
        "&:hover": {
            color: ({ theme }) => theme.colorPrimaryLighter
        },
        cursor: "pointer",
        margin: "0 5px"
    }
})

const Groups = (props: IProps) => {
    const theme = useTheme<ITheme>();
    const classes = useStyles({ ...props, theme });
    const groups = useGroups();

    const deleteItem = (id: string) => () => {
        let app = getApp();
        let db = getFirestore(app);
        deleteDoc(doc(db, "groups", id));
        //firebase.db.ref(`/portfolio/${id}`).set(null);
    };

    const columns = [
        {
            key: "id",
            label: "ID",
            width: 100
        },
        {
            key: "title",
            label: "Title",
            width: 400
        },
        {
            key: "slug",
            label: "Slug",
            width: 200
        },
        {
            key: "actions",
            label: <Link to={`/backend/group/`}>New group</Link>,
            width: 200,
            float: "right",
            customContent: (row: IGroup) => {
                return (
                    <>
                        <Link to={`/backend/group/${row.id}`}>Edit</Link>
                        <span
                            className={classes.action}
                            onClick={deleteItem(row.id)}
                        >
                            Delete
                        </span>
                    </>
                );
            }
        }
    ];


    return (
        <div className={classes.root}>
            <Table columns={columns} data={groups} />
        </div>
    );
}

export default Groups;